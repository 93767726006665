import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Footer from "../../components/footer"
import { StaticImage } from "gatsby-plugin-image"

export default class Solvents extends React.Component {

  render() {

    return (

      <Layout>

        <Seo title="SPCO" lang="en" />

        <section className="wow animate__fadeIn">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-5 col-md-6 text-center md-margin-30px-bottom wow animate__fadeInLeft">
                <StaticImage
                  src="../../images/s1.jpg"
                  formats={["auto", "webp", "avif"]}
                  alt=""
                  className="border-radius-6 w-100"
                />
              </div>
              <div className="col-12 col-lg-7 col-md-6 text-center text-md-start padding-eight-lr lg-padding-six-lr md-padding-15px-lr wow animate__fadeInRight" data-wow-delay="0.2s">

                <span className="text-deep-pink alt-font margin-10px-bottom d-inline-block text-medium">Industrial solvents</span>

                <h6 className="alt-font text-extra-dark-gray">Solvent 402</h6>
                <p>White spirit solvents are hydrocarbon compounds that are produced from cutting kerosene. These materials have aromatic and linear hydrocarbon compounds. These compounds cause to have special fragrant and properties. Based on the percent of aromatic compounds, these materials divide into two categories of high & low aromatic.</p>
                <p>White spirit is colorless and a mixture of 7 to 12 aliphatic and cyclic aliphatic hydrocarbons with a very low solubility in water and an odor threshold between 0.5 to 5 mg/m3. White spirits are mainly used as extraction solvents, diluents, detergents, carriers of additives and raw materials in many industries such as paint-resin, varnish, adhesive, thinner, wire, waxes and polishes. This material due to its special properties is used for cleaning oils from the surface of metals, greases, carbon etc. </p>

                <h6 className="alt-font text-extra-dark-gray">Solvent 404</h6>
                <p>These materials are divided into two categories as Aliphatic, and Aromatic (Benzene, toluene, xylene, etc.).</p>
                <strong className="mb-4">The uses of solvent 404 are as same as solvent 406.</strong>

                <h6 className="alt-font text-extra-dark-gray">Solvent 406</h6>
                <p>Solvent 406 is divided into two categories such as Aliphatic, and Aromatic (Benzene, toluene, xylene, etc.). In general, these solvents are extracted from crude oil. These solvents can be used in different fields. For instance, linear paraffin has a low viscosity, while branched paraffin has high viscosity and good low-temperature properties. The combination of these solvents with normal viscosity and low temperature provides us normal temperature.  </p>
                <strong>The uses of solvent 406</strong>
                <ol>
                  <li>Producing paints and polishes</li>
                  <li>Cleaning the grease from the surface of metals</li>
                  <li>Producing wood antibacterial material</li>
                  <li>Used in preparing bitumen and production of car tires</li>
                  <li>Cleaning solution for industrial equipment and machines</li>
                </ol>

                <a className="btn btn-primary me-2" href="/files/Solvent-406.pdf">Download Solvent-406</a>
                <a className="btn btn-primary" href="/files/Solvent-404.pdf">Download Solvent-404</a>

              </div>
            </div>
          </div>
        </section>

        <Footer />

      </Layout>

    )

  }

}